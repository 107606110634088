import React from 'react'
import {Container} from '@mui/material';
import '../style_sheets/footer.css';

const Footer = () => {
  return (
    <Container >
        <p style={{textAlign:"center"}} id='copyRight' > © 2023 晚晚Ailsa </p>
    </Container>
  )
}

export default Footer