import '../style_sheets/header.css'
import React from 'react'
import { Container, Button, Avatar, Stack, Typography  } from '@mui/material';
import LiveStatusBadge from './LiveStatusBadge'


const avatar = require('../avatar.png');
const Header = ({liveStatus}) => {
  return (

    <Container maxWidth="lg" className="headerContainer">
        <div className='Intro'>
        <Stack
          spacing={{ xs: 1, sm: 2, md: 2 }}
          justifyContent="center"
          alignItems="left"
          >
            <Avatar className="Avatar" alt="晚晚Ailsa" src={avatar} sx={{borderColor: 'white',border: 1, width: 150, height: 150 }}/>
            <Typography variant="h6" gutterBottom component="div" color="common.white">
              晚晚Ailsa
            </Typography>
            <Typography variant="caption" display="block" gutterBottom color="common.white">
            晚是全世界的夜晚 安是有你在的心安
            </Typography>
            <Typography variant="caption" gutterBottom color="common.white">
            点击歌名可以复制该歌曲点歌弹幕哦
            </Typography>

            <div className='btnStackDiv'>
              <Stack id="homeBtnStack" 
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="left"
              alignItems="left"
              >                
                <LiveStatusBadge id="liveBadge" statusCode={liveStatus} />
                <Button id="biliSpaceBtn" 
                size="small"
                variant="contained" 
                color="primary" 
                href="https://space.bilibili.com/45280964/" 
                target="_blank">
                  B站空间
                </Button>                
              </Stack>              
            </div>
          </Stack>


            

        </div>
        
        
    </Container>
    
  )
}

Header.defaultProps = {
    textAlign: 'center'
}





export default Header