import { useState, useMemo  } from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination, 
  Container, Input, Button, Stack, Box , IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Paper} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import {ToastContainer, toast } from 'react-toastify';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css'
import "../style_sheets/songList.css"


const SongList = (
  SongData
  ) => {
  /*theme settings*/ 
  const [SongDataJson, setSongDataJson] = useState(SongData.SongData);
  const [locale, setLocale] = useState('zhCN');
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  );
  /*theme settings*/ 
  
  
  const [searchTerm, setSeartchTerm] = useState(''); 
  const [copied, setCopied] = useState(false); 
  var songObj = SongDataJson[Math.floor(Math.random()*SongDataJson.length)]; //randomly select a song object

  /************************Toaster************************/
  //toaster function for random song select
  var notify = () => {
    toast("弹幕:<点歌 " + songObj.songName + "> 已复制\n\n亲可以直接发送弹幕点歌了哟", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
    });
  }

  var notifyRowClick = (clickedSongName) => {
    toast("弹幕：<点歌 " + clickedSongName + "> 已复制\n\n亲可以直接到直播间黏贴并发送弹幕点歌了哟", {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
    });
  }

  function rowClickHandler(rowSongName) {
    notifyRowClick(rowSongName);
    setCopied(true);
  }

  function randomBtnClickHandler() {
    notify();
    setCopied(!copied);
  }




  /************************search songs************************/
  const [tableData, setTableData] = useState(SongData.SongData);
  const handleChnageSearchTerm = (event) => {
    setTableData(SongDataJson)
    setSeartchTerm(event.target.value)
    setPage(0)
  }


  /************************Column header setting************************/
  const columnName = [
    { id: 'songName', label: '歌名', minWidth: 100 },
    { id: 'singer', label: '歌手', minWidth: 30 },
    {
      id: 'language',
      label: '语言',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'size',
      label: '备注',
      minWidth: 40,
      align: 'left'
    }
  ]

  /************************pagination************************/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (rowsPerPage != 10){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (event.target.value != 10){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box  sx={{ flexShrink: 0, ml: 2.5, mr: 35}}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
/************************for pagination************************/


  return (
    <Container maxWidth="lg" className="container">
      <ToastContainer />
      <Container id="SongListArea">
        <div id="searchBar">
          <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          justifyContent="center"
          alignItems="center"
          >
          <Input id='searchInput' type='text' placeholder="搜索歌曲:输入歌名或歌手"  onChange={handleChnageSearchTerm} style={{width: '80%', margin:10}}/>
          <CopyToClipboard
            key='copyToClipBoard1'
            text={"点歌 "+  songObj.songName.trim()}
            >
            <span> 
              <Button size='small' className="randomSongBtn" color="secondary" variant="contained" onClick={randomBtnClickHandler}>
              随机点歌
              </Button>
            </span>
          </CopyToClipboard>
          </Stack>
        </div>

        <TableContainer component={Paper} style={{backgroundColor : 'transparent'}} className='songListContainer' >
            <Table className='songTable' sx={{ minWidth: 650 }}  aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columnName.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.filter((row) => {
                  if (searchTerm==="") {
                    return row;
                  }
                  else if (row.songName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          row.singer.toLowerCase().includes(searchTerm.toLowerCase())  || 
                          row.language.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          row.note.toLowerCase().includes(searchTerm.toLowerCase()) 
                            ){
                    return row;
                  }              
                })
                .sort((a,b) => a.songName.length - b.songName.length || a.songName.localeCompare(b.songName))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //slice the array for pagination
                .map((row) => (
                  
                  <CopyToClipboard
                  text={"点歌 "+ row.songName.trim()}
                  key={row.id}
                  >
                    <TableRow
                      hover
                      className="tableRow"
                      color = "common.white"
                      onClick={() => rowClickHandler(row.songName.trim())}
                      sx={{ '&:last-child td, &:last-child th': { borderBottom: 5 } }}
                    >
                      <TableCell >{row.songName.trim()}</TableCell>
                      <TableCell>{row.singer.trim()}</TableCell>
                      <TableCell >{row.language.trim()}</TableCell>
                      <TableCell >{row.note.trim()}</TableCell>
                    </TableRow>
                  </CopyToClipboard>
                  
                ))}
              </TableBody>
            </Table>
         
        </TableContainer>
        {/* Themeprovider for chinese pagination settings */}
        <ThemeProvider theme={themeWithLocale}> 
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, { label: '全部', value : -1 }]}
            component="div"
            count={tableData.filter((row) => {
              if (searchTerm==="") {
                return row;
              }
              else if (row.songName.toLowerCase().includes(searchTerm.toLowerCase()) || 
              row.singer.toLowerCase().includes(searchTerm.toLowerCase())  || 
              row.language.toLowerCase().includes(searchTerm.toLowerCase()) || 
              row.note.toLowerCase().includes(searchTerm.toLowerCase()) 
              ){
                return row;
              }
            }).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="每页行数："
            labelDisplayedRows={ (from=page) => (`${from.from} - ${from.to === -1 ? from.count : from.to}， 共 ${from.count} 首`)}
            ActionsComponent={TablePaginationActions}
          />
        </ThemeProvider>
      </Container>
    </Container>
  )
}

export default SongList