import bgVideo from './assets/Videos/stars_bg.mp4';
import Header from "./components/Header";
import SongList from "./components/SongList";
import Footer from "./components/Footer";
import React, {useEffect, useState} from 'react';
import { Stack, CircularProgress  } from "@mui/material";
import { ScrollToTop } from "./components/ScrollToTop";
import "./App.css"


function App() {
  const [liveStatusCode, setLiveStatusCode] = useState(0);
  const roomId = "1420300";
  useEffect(    
    () =>{
      var isSubscribed = true;
      const fetchData = async () => {
        // get the data from the api
        const data = await fetch(`.netlify/functions/GetRoomInfo?roomId=${roomId}`).then(res => res.json());
        if (isSubscribed){
          setLiveStatusCode(data.data.live_status);
        }
      } 
      fetchData().catch(console.error);

      return () => isSubscribed = false;
    }, []
  );

  //start calling mongo for song data
  const [mongoSongData, setMongoSongData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function fetchSongs() {
      try{
        setLoading(true);
        const data = await fetch(`.netlify/functions/GetSongs`).then(res => res.json());
        setMongoSongData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    fetchSongs();
  }, []);

  function createData(id, songName, singer, language, note) {
    return { id, songName, singer, language, note };
  };

  var i =0;
  const songData = [];
  mongoSongData.forEach(song => {
    songData.push(createData(i++, song.songName, song.singer, song.language, song.note));
  });

  return (
    <>
    <div className="App">
      <video autoPlay muted loop id="bgVideo">
        <source src={bgVideo} type="video/mp4"/>
      </video>
      <div className="AppContainer">
        <Stack 
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Header
            liveStatus={liveStatusCode}
          />
          {loading ? <CircularProgress/> : <SongList SongData={songData} />}
          <Footer/>
        </Stack>
      </div>
    </div>
    <ScrollToTop id="scrollToTopBtn"/>
    </>
  );
}

export default App;
